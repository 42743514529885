import { TFunction } from "i18next";
import {
  IFilteredColumn,
  FilterType,
} from "../../../../components/DataTable/DataTableFilters/DataTableFilters";
import { ISelectOption } from "../../../../components/DataTable/DataTableFilters/DataTableFilterTypes/SelectFilter";
import { Statuses } from "../../../../components/DelegeeStatus/DelegeeStatus";
import {
  FmpFilters,
  VisitorBookingFilters,
} from "../../../../constants/filtering.constants";
import { VisitorBookingTableModel } from "../../../Visitors/BookingOverview/VisitorBookingDataTable/VisitorBookingDataTable";
import { IDelegeeDataTableModel } from "../../MyFleet/DelegeesDataTable/DelegeesDataTable";
import { VisitorBookingStatus } from "../../../../models/visitor-booking";
import { IParkingRightsDataTableModel } from "../ParkingRightsDataTable/FleetMembersTable";

type FilteredColumnObjectBuilder = {
  buildParkingRightsColumns: (
    t: TFunction,
    productOptions: ISelectOption[],
    useGetDelegatedParkingRightsNew: boolean
  ) => IFilteredColumn<IParkingRightsDataTableModel>[];
  buildVehiclesMyProductsColumns: (
    t: TFunction,
    productOptions: ISelectOption[],
  ) => IFilteredColumn<IParkingRightsDataTableModel>[];
  buildVehiclesMyFleetColumns: (
    t: TFunction,
    productOptions: ISelectOption[],
  ) => IFilteredColumn<IParkingRightsDataTableModel>[];
  buildDelegeesColumns: (
    t: TFunction,
    productOptions: ISelectOption[], 
    useGetDelegatedParkingRightsNew: boolean
  ) => IFilteredColumn<IDelegeeDataTableModel>[];
  buildVisitorBookingsColumns: (
    t: TFunction,
    productOptions: ISelectOption[],
    facilityOptions: ISelectOption[]
  ) => IFilteredColumn<VisitorBookingTableModel>[];
};

const filteredColumnObjectBuilder: FilteredColumnObjectBuilder = (() => {
  const buildStatusFilterSelectOptions = (t: TFunction): ISelectOption[] => {
    return [
      { label: t("globals:active"), value: Statuses.Accepted },
      { label: t("globals:pending"), value: Statuses.Pending },
    ];
  };

  const buildVisitorBookingStatusFilterSelectOptions = (
    t: TFunction
  ): ISelectOption[] => {
    return [
      { label: t("globals:booked"), value: VisitorBookingStatus.Booked },
      { label: t("globals:cancelled"), value: VisitorBookingStatus.Cancelled },
      { label: t("globals:draft"), value: VisitorBookingStatus.Draft },
    ];
  };

  const buildInvitationsStatusFilterSelectOptions = (
    t: TFunction
  ): ISelectOption[] => {
    return [
      { label: t("globals:active"), value: true },
      { label: t("globals:pending"), value: false },
    ];
  };

  const buildParkingRightsColumns = (
    t: TFunction,
    productOptions: ISelectOption[],
    useGetDelegatedParkingRightsNew: boolean
  ): IFilteredColumn<IParkingRightsDataTableModel>[] => {
    const columns: IFilteredColumn<IParkingRightsDataTableModel>[] = [
      {
        id: "name",
        label: t("parkingRightsDataTable.name"),
        width: 200,
        enableFiltering: true,
        filter: {
          type: FilterType.Text,
          key: FmpFilters.name,
          placeholder: t("dataTable:filterOnName"),
        },
      },
      {
        id: "email",
        label: t("parkingRightsDataTable.email"),
        width: 200,
        enableFiltering: true,
        filter: {
          type: FilterType.Text,
          key: FmpFilters.email,
          placeholder: t("dataTable:filterOnEmail"),
        },
      },
      {
        id: "product",
        label: t("parkingRightsDataTable.products"),
        width: 300,
        disableSorting: useGetDelegatedParkingRightsNew,
        enableFiltering: true,
        filter: {
          type: FilterType.Multiselect,
          key: FmpFilters.products,
          placeholder: t("dataTable:filterOnProducts"),
          options: productOptions,
          },
      },
      getInvitationDateColumn(t),
      {
        id: "status",
        label: t("parkingRightsDataTable.status"),
        width: 80,
        enableFiltering: true,
        filter: {
          type: FilterType.Multiselect,
          key: FmpFilters.statuses,
          placeholder: t("dataTable:filterOnStatus"),
          options: buildStatusFilterSelectOptions(t).map((o) => ({
            label: o.label,
            value: o.value,
          })),
        },
      },
    ];

    return columns;
  };

  const buildVehiclesMyFleetColumns = (
    t: TFunction,
    productOptions: ISelectOption[]
  ): IFilteredColumn<IParkingRightsDataTableModel>[] => {
    const columns: IFilteredColumn<IParkingRightsDataTableModel>[] = [
      getVehicleNumberplateColumn(t),
      getVehicleDescriptionColumn(t),
      getVehicleProductsColumn(t, productOptions),
      getAssignedDateColumn(t)
    ];

    return columns;
  };

  const buildVehiclesMyProductsColumns = (
    t: TFunction,
    productOptions: ISelectOption[]
  ): IFilteredColumn<IParkingRightsDataTableModel>[] => {
    const columns: IFilteredColumn<IParkingRightsDataTableModel>[] = [
      getVehicleNumberplateColumn(t),
      getVehicleDescriptionColumn(t),
      getVehicleProductsColumn(t, productOptions),
      getAssignedDateColumn(t),
      getStartedDateColumn(t),
    ];

    return columns;
  };

  const getVehicleNumberplateColumn = (t: TFunction): IFilteredColumn<IParkingRightsDataTableModel> => {
    return {
      id: "numberPlate",
      label: t("vehiclesDataTable.numberPlate"),
      width: 200,
      enableFiltering: true,
      filter: {
        type: FilterType.Text,
        key: FmpFilters.numberPlate,
        placeholder: t("dataTable:filterOnNumberPlate"),
      },
    };
  }

  const getVehicleDescriptionColumn = (t: TFunction): IFilteredColumn<IParkingRightsDataTableModel> => {
    return {
      id: "description",
      label: t("vehiclesDataTable.description"),
      width: 200,
      enableFiltering: true,
      filter: {
        type: FilterType.Text,
        key: FmpFilters.description,
        placeholder: t("dataTable:filterOnDescription"),
      },
    };
  }

  const getVehicleProductsColumn = (t: TFunction, productOptions: ISelectOption[]): IFilteredColumn<IParkingRightsDataTableModel> => {
    return {
      id: "product",
      label: t("vehiclesDataTable.products"),
      width: 300,
      enableFiltering: true,
      disableSorting: true,
      filter: {
        type: FilterType.Multiselect,
        key: FmpFilters.products,
        placeholder: t("dataTable:filterOnProducts"),
        options: productOptions,
      },
    };
  }

  const getAssignedDateColumn = (t: TFunction): IFilteredColumn<IParkingRightsDataTableModel> => {
      return {
        id: "assignedDate",
        label: t("vehiclesDataTable.assignedDate"),
        width: 200,
        enableFiltering: true,
        filter: {
          type: FilterType.Date,
          key: FmpFilters.assignedDate,
          placeholder: t("dataTable:filterOnAssignedDate"),
        },
      }
    }

    const getStartedDateColumn = (t: TFunction): IFilteredColumn<IParkingRightsDataTableModel> => {
      return {
        id: "startedDate",
        label: t("vehiclesDataTable.startedDate"),
        width: 200,
        enableFiltering: true,
        filter: {
          type: FilterType.Date,
          key: FmpFilters.startedDate,
          placeholder: t("dataTable:filterOnStartedDate"),
        },
      }
    }
  

  const getInvitationDateColumn = (t: TFunction): IFilteredColumn<IParkingRightsDataTableModel> => {
    return {
        id: "invitationDate",
        label: t("parkingRightsDataTable.invitationDate"),
        width: 200,
        enableFiltering: true,
        filter: {
          type: FilterType.Date,
          key: FmpFilters.invitationDate,
          placeholder: t("dataTable:filterOnInvitationDate"),
        },
    }
  };

  const buildDelegeesColumns = (
    t: TFunction,
    productOptions: ISelectOption[],
    useGetDelegatedParkingRightsNew: boolean
  ): IFilteredColumn<IDelegeeDataTableModel>[] => {
    const columns: IFilteredColumn<IDelegeeDataTableModel>[] = [
      {
        id: "name",
        label: t("delegeesDataTable.name"),
        width: 200,
        enableFiltering: true,
        filter: {
          type: FilterType.Text,
          key: FmpFilters.name,
          placeholder: t("dataTable:filterOnName"),
        },
      },
      {
        id: "email",
        label: t("delegeesDataTable.email"),
        width: 200,
        enableFiltering: true,
        filter: {
          type: FilterType.Text,
          key: FmpFilters.email,
          placeholder: t("dataTable:filterOnEmail"),
        },
      },
      {
        id: "products",
        label: t("delegeesDataTable.products"),
        width: 300,
        disableSorting: useGetDelegatedParkingRightsNew,
        enableFiltering: true,
        filter: {
          type: FilterType.Multiselect,
          key: FmpFilters.products,
          placeholder: t("dataTable:filterOnProducts"),
          options: productOptions,
          },
      },
      {
        id: "invitationDate",
        label: t("delegeesDataTable.creationDate"),
        width: 200,
        enableFiltering: true,
        filter: {
          type: FilterType.Date,
          key: FmpFilters.invitationDate,
          placeholder: t("dataTable:filterOnCreationDate"),
        }
      },
      {
        id: "status",
        label: t("delegeesDataTable.status"),
        width: 80,
        enableFiltering: true,
        filter: {
          type: FilterType.Multiselect,
          key: FmpFilters.invitationsAccepted,
          placeholder: t("dataTable:filterOnStatus"),
          options: buildInvitationsStatusFilterSelectOptions(t).map((o) => ({
            label: o.label,
            value: o.value,
          })),
        },
      },
    ];

    return columns;
  };

  const buildVisitorBookingsColumns = (
    t: TFunction,
    productOptions: ISelectOption[],
    facilityOptions: ISelectOption[]
  ): IFilteredColumn<VisitorBookingTableModel>[] => {
    const columns: IFilteredColumn<VisitorBookingTableModel>[] = [
      {
        id: "customerName",
        enableFiltering: true,
        label: t("visitorBookingsTable.customerName"),
        filter: {
          type: FilterType.Text,
          key: VisitorBookingFilters.name,
          placeholder: t("dataTable:filterOnName"),
        },
      },
      {
        id: "customerEmail",
        enableFiltering: true,
        label: t("visitorBookingsTable.customerEmailAddress"),
        filter: {
          type: FilterType.Text,
          key: VisitorBookingFilters.email,
          placeholder: t("dataTable:filterOnEmail"),
        },
      },
      {
        id: "facility",
        enableFiltering: true,
        label: t("visitorBookingsTable.facility"),
        filter: {
          type: FilterType.Multiselect,
          key: VisitorBookingFilters.facility,
          placeholder: t("dataTable:selectFacility"),
          options: facilityOptions
        },
      },
      {
        id: "product",
        enableFiltering: true,
        label: t("visitorBookingsTable.product"),
        width: 200,
        filter: {
          type: FilterType.Multiselect,
          key: VisitorBookingFilters.products,
          placeholder: t("dataTable:selectProduct"),
          options: productOptions,
        },
      },
      {
        id: "creationDate",
        enableFiltering: true,
        label: t("visitorBookingsTable.createdAt"),
        filter: {
          type: FilterType.Date,
          key: VisitorBookingFilters.creationDate,
          placeholder: t("dataTable:filterOnCreationDate"),
          customDateKeys: {
            startKey: 'creationDateRangeStart',
            endKey: 'creationDateRangeEnd'
          }
        },
      },
      {
        id: "startDateTime",
        enableFiltering: true,
        label: t("visitorBookingsTable.startDateTime"),
        filter: {
          type: FilterType.Date,
          key: VisitorBookingFilters.startDate,
          placeholder: t("dataTable:filterOnStartDate"),
          customDateKeys: {
            startKey: 'startDateRangeStart',
            endKey: 'startDateRangeEnd'
          }
        },
      },
      {
        id: "endDateTime",
        enableFiltering: true,
        label: t("visitorBookingsTable.endDateTime"),
        filter: {
          type: FilterType.Date,
          key: VisitorBookingFilters.endDate,
          placeholder: t("dataTable:filterOnEndDate"),
          customDateKeys: {
            startKey: 'endDateRangeStart',
            endKey: 'endDateRangeEnd'
          }
        },
      },
      {
        id: "statuses",
        label: t("visitorBookingsTable.status"),
        enableFiltering: true,
        filter: {
          type: FilterType.Multiselect,
          key: VisitorBookingFilters.statuses,
          placeholder: t("dataTable:filterOnStatus"),
          options: buildVisitorBookingStatusFilterSelectOptions(t).map((o) => ({
            label: o.label,
            value: o.value,
          })),
        },
      },
    ];

    return columns;
  };

  return {
    buildParkingRightsColumns,
    buildVehiclesMyProductsColumns,
    buildVehiclesMyFleetColumns,
    buildDelegeesColumns,
    buildVisitorBookingsColumns,
  };
})();

export default filteredColumnObjectBuilder;
