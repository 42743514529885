import React, { useEffect, Dispatch, useCallback, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import DelegeeStatus, { Statuses } from "../../../../components/DelegeeStatus/DelegeeStatus";
import { RootReducer } from "../../../../store/reducers";
import parkingProductsActions from "../../../../store/actions/parkingProducts.actions";
import { connect } from "react-redux";
import moment from "moment";
import { IDataTableRow } from "../../../../components/DataTable/DataTableBody/DataTableBody";
import useAppContext from "../../../../context/hooks/useAppContext";
import {
  DelegatedParkingProductModel,
  DelegatedParkingRight,
} from "../../../../models/delegations/DelegatedParkingProductsModel";
import { isUndefined, isNull } from "underscore";
import useMyProductsContext from "../../../../context/hooks/useMyProductsContext";
import FleetManagerDataTable, {
  IFleetManagerDataTableModel,
} from "../../FleetManagerDataTable/FleetManagerDataTable";
import useFleetManagerContext from "../../../../context/hooks/useFleetManagerContext";
import { ForFleetManager } from "../../../../models/ForFleetManager";
import { WithFiltersAndPaging } from "../../../../models/filters/WithFiltersAndPaging";
import {
  DataTableContextFilters,
  useDataTableContext,
} from "../../../../components/DataTable/DataTableContext/DataTableContextProvider";
import filteredColumnObjectBuilder from "../ObjectBuilders/FilteredColumnObjectBuilder";
import { FmpFilters } from "../../../../constants/filtering.constants";
import { FilterType, IFilteredColumn } from "../../../../components/DataTable/DataTableFilters/DataTableFilters";
import { useOnBulkImportFinishedCallback } from "../../../../hooks/useOnBulkImportFinishedCallback";
import { ProductChip, selectUseSingleDirectVehicleAssignment, useCurrentlySelectedParkingProduct, useGetOccupiedParkingRightsCount, useProductOptions, useSharedProductsMenuAction } from "./common";
import { useAppSelector } from "../../../../store";
import { isNil } from "lodash";

const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

export interface IParkingRightsDataTableModel extends IFleetManagerDataTableModel {
  product: JSX.Element;
}

interface FleetMembersTableProps extends StateProps, DispatchProps {
  subHeading?: ReactNode
  onRevokeClicked: () => void;
}

const getDelegeeStatus = (parkingRight: DelegatedParkingProductModel) => {
  return parkingRight.status as Statuses;
};

const _createData = (
  delegatedParkingRight: DelegatedParkingRight
): IParkingRightsDataTableModel => {
  return {
    rowKey: delegatedParkingRight.parkingRight.parkingRightId,
    name: delegatedParkingRight.delegee.name,
    email: delegatedParkingRight.delegee.emailAddress,
    product: <Wrapper><ProductChip location={delegatedParkingRight.parkingRight.location} name={delegatedParkingRight.parkingRight.name} /></Wrapper>,
    invitationDate: moment(delegatedParkingRight.parkingRight.created).format("DD/MM/YYYY"),
    status: <DelegeeStatus type={getDelegeeStatus(delegatedParkingRight.parkingRight)} />,
  };
};

const FleetMembersTable = (props: FleetMembersTableProps) => {
  const { onRevokeClicked, parkingProducts, getDelegatedParkingRights } = props;
  const { t } = useTranslation(["myProducts", "globals", "dataTable", "fleetManagerTable"]);

  const { myProductsState, setSorting } = useMyProductsContext();
  const { fleetManagerState } = useFleetManagerContext();
  const dataTableContext = useDataTableContext();
  const { appState } = useAppContext();

  const { delegatedParkingProducts } = parkingProducts;

  const useSingleDirectVehicleAssignment = useAppSelector(selectUseSingleDirectVehicleAssignment);
  const getTotalOccupiedParkingRights = useGetOccupiedParkingRightsCount();


  const getFilters = (): DataTableContextFilters[] => [
    {
      key: FmpFilters.placeId,
      type: FilterType.QueryParam,
      value: myProductsState.selectedLocation.toString(),
    },
    {
      key: FmpFilters.sortBy,
      type: FilterType.QueryParam,
      value: myProductsState.dataTable.sortBy,
    },
    {
      key: FmpFilters.orderDesc,
      type: FilterType.QueryParam,
      value: myProductsState.dataTable.orderDesc?.toString(),
    },
    ...(dataTableContext.state.filters as DataTableContextFilters[])
  ];

  const getRequestModel = (): WithFiltersAndPaging<ForFleetManager> => {
    const request: WithFiltersAndPaging<ForFleetManager> = {
      entity: {
        seasonTicketOwnerCrmId: appState.user.seasonTicketOwnerCrmId as string,
        language: appState.selectedLanguage,
      },
      pagination: {
        pageSize: dataTableContext.state.pageSize!,
        pageNumber: dataTableContext.state.pageNumber!,
      },
      filters: getFilters(),
    };

    return request;
  };

  const fetchDelegatedParkingRights = () => {   
    if (appState.user.seasonTicketOwnerCrmId) {
      getDelegatedParkingRights(getRequestModel());
    }
  };

  useOnBulkImportFinishedCallback(fetchDelegatedParkingRights);

  const memoizedFetch = useCallback(
    (): void => fetchDelegatedParkingRights(), // eslint-disable-next-line
    [
      dataTableContext.state.pageSize,
      dataTableContext.state.pageNumber,
      myProductsState.dataTable.orderDesc,
      myProductsState.dataTable.sortBy,
      myProductsState.selectedLocation,
      appState.user.seasonTicketOwnerCrmId,
      appState.selectedLanguage,
    ]
  );

  useEffect(() => {
    memoizedFetch();
  }, [memoizedFetch]);

  const productTableOptions = useProductOptions();
  const sharedProductActions = useSharedProductsMenuAction();

  const columns: IFilteredColumn<IParkingRightsDataTableModel>[] = filteredColumnObjectBuilder.buildParkingRightsColumns(
    t, productTableOptions, props.useGetDelegatedParkingRightsNew
  );

  const onSort = (
    event: React.MouseEvent<unknown>,
    property: keyof IParkingRightsDataTableModel
  ) => {
    setSorting(property.toString());
  };

  const getTableRows = (): IParkingRightsDataTableModel[] => {
    if (
      !isNull(delegatedParkingProducts.pages) &&
      !isUndefined(delegatedParkingProducts.pages) &&
      !isUndefined(delegatedParkingProducts.pages[dataTableContext.state.pageNumber!])
    ) {
      return delegatedParkingProducts.pages[dataTableContext.state.pageNumber!].data?.map(
        _createData
      ) as IParkingRightsDataTableModel[];
    }

    return [];
  };

  const getLoadingState = () => {
    if (
      !isNull(delegatedParkingProducts.pages) &&
      !isUndefined(delegatedParkingProducts.pages) &&
      !isUndefined(delegatedParkingProducts.pages[dataTableContext.state.pageNumber!])
    ) {
      return delegatedParkingProducts.pages[dataTableContext.state.pageNumber!].loading;
    }

    return false;
  };

  const isLoading = getLoadingState();
  const rows = getTableRows();

  const selectedLocatedProduct = useCurrentlySelectedParkingProduct();

  const getRegistrationIdByTableRow = (focusedRow: IDataTableRow | null): string | null => {
    const parkingRightId = focusedRow?.rowKey;
    const currentPageNumber = dataTableContext.state.pageNumber!;
    const currentPage = delegatedParkingProducts.pages?.[currentPageNumber]; 
    if (isNil(currentPage)) {
      return null;
    }

    const delegatedParkingRight = currentPage.data?.find(
      (delegatedPR) => delegatedPR.parkingRight.parkingRightId === parkingRightId
    );

    return delegatedParkingRight?.delegee.registrationId ?? null;
  };

  const isResendInviteDisabled = (focusedRow: IDataTableRow | null) => {
    const parkingRightRow = (focusedRow as IParkingRightsDataTableModel) || null;

    return parkingRightRow === null || parkingRightRow.status.props?.type !== Statuses.Pending;
  };

  return (
    <FleetManagerDataTable
      columns={columns}
      rows={rows}
      key={myProductsState.selectedLocation}
      isLoading={isLoading}
      orderBy={myProductsState.dataTable.sortBy ?? ""}
      totalPages={delegatedParkingProducts.totalPages}
      getRegistrationIdFromTableRow={getRegistrationIdByTableRow}
      isResendInviteDisabled={isResendInviteDisabled}
      onFilterClicked={fetchDelegatedParkingRights}
      onResetFiltersClicked={fetchDelegatedParkingRights}
      onSort={onSort}
      translationOverrideKey={"parkingRights"}
      order={myProductsState.dataTable.orderDesc ? "desc" : "asc"}
      heading={{
        options: {
          useHeading: true,
          showHeadingCount: true,
        },
        title: useSingleDirectVehicleAssignment ? t("globals:parkingRights") : t("delegatedParkingRights"),
        count: getTotalOccupiedParkingRights(selectedLocatedProduct || {})
      }}
      filtering={{
        options: {
          useFilters: true,
        },
      }}
      actions={{
        options: {
          useActions: true,
        },
        add: {
          useAdd: !useSingleDirectVehicleAssignment,
          showAdd: !useSingleDirectVehicleAssignment,
          addLabel: t("fleetManagerTable:addDelegee"),
          onAddClicked: sharedProductActions.onAddDelegee,
        },
        revoke: {
          useRevoke: true,
          showRevoke: fleetManagerState.selectedRowKeys.length > 0,
          revokeLabel: `${t("globals:revoke")} (${fleetManagerState.selectedRowKeys.length})`,
          onRevokeClicked: onRevokeClicked,
        },
        menu: sharedProductActions.menu,
      }}
      subHeading={props.subHeading}
    />
  );
};

const mapStateToProps = (state: RootReducer) => {
  const { parkingProducts } = state;
  const { environment } = state;
  const useGetDelegatedParkingRightsNew = environment.featureFlags?.find(
    (item) => item.name === "UseGetDelegatedParkingRightsNew"
  );

  return {
    parkingProducts,
    useGetDelegatedParkingRightsNew: useGetDelegatedParkingRightsNew?.value ?? false,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  getDelegatedParkingRights: (request: WithFiltersAndPaging<ForFleetManager>) =>
    dispatch(parkingProductsActions.getDelegatedParkingProducts(request)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(FleetMembersTable);
